import { isMobile } from 'react-device-detect';
import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider';

import Slide1 from 'src/slides/desktop/de/chapter_4/sub_2/slide1';
import WrapperMobile from 'src/slides/mobile/de/chapter_4/sub_2/wrapperMobile';

import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Wer war Tuchatschewski | Schlacht bei Warschau" lang="de" description="Die Figur eines Bolschewiki-Napoleons, der Europa in Brand setzen sollte. " />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Wer war Tuchatschewski | Schlacht bei Warschau" lang="de" description="Die Figur eines Bolschewiki-Napoleons, der Europa in Brand setzen sollte. " />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
