import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import Quote from 'src/components/desktop/quote/quote';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_2/4-2-1-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  modal: file(relativePath: { eq: "chapter_4/sub_2/4-2-1-modal.png"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  }
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          flexi
          scene={2}
          move={{ x: 10, y: 20 }}
        >
          <div css={{
            alignSelf: 'center', width: '100%', margin: '12% auto 0',
          }}
          >
            <Quote fontColor="white" quoteText={query.allMdx.edges[0].node.body} quoteAuthor={query.allMdx.edges[0].node.frontmatter.author} modalImage={assetQuery.modal.childImageSharp.fixed} />


          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
